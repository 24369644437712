import { useCallback, useEffect } from "react";
import { config, useSpring } from "react-spring";
import { reaction } from "mobx";

import { Image } from "@components/common/ui/Image";

import { useCursor } from "@core/hooks";

import { getVectorImageByName } from "@assets/images";

import * as S from "./styled";

export const Cursor: React.FC = () => {
	const cursor = useCursor();
	const [cursorStyle, cursorAnimationApi] = useSpring(() => ({ x: 0, y: 0 }));
	// const [plumeStyle, plumeAnimationApi] = useSpring(() => ({ x: 0, y: 0, scale: 1 }));
	const [pieceStyle, pieceAnimationApi] = useSpring(() => ({
		x: 0,
		y: 0,
		innerX: "-5%",
		innerY: "5%",
		scale: 1,
		wrapperScale: 1,
		wrapperRotate: 0,
		additionalRotate: 0,
	}));

	const handleMouseMove = useCallback(
		(event: MouseEvent) => {
			// plumeAnimationApi.start({ x: event.clientX, y: event.clientY });
			cursorAnimationApi.set({
				x: event.clientX,
				y: event.clientY,
			});
		},
		[cursorAnimationApi]
	);

	useEffect(() => {
		document.addEventListener("mousemove", handleMouseMove);

		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
		};
	}, [handleMouseMove]);

	useEffect(
		() =>
			reaction(
				() => [cursor.getState(), cursor.getCursorClicked()],
				([state, clicked]) => {
					const active = state === "pointer";
					const compressed = active && clicked;

					// plumeAnimationApi.start({ scale: active ? (compressed ? 1.25 : 1.5) : 1 });
					pieceAnimationApi.start({
						x: active ? (compressed ? 0.5 : 0.7) : 0,
						y: active ? (compressed ? 0.5 : 0.7) : 0,
						innerX: active ? "0%" : "-5%",
						innerY: active ? "0%" : "5%",
						scale: active ? 1 / 5 : 1,
						wrapperScale: active ? (compressed ? 2.8 : 3) : 1,
						wrapperRotate: active ? (compressed ? 45 : 45) : 0,
						config: compressed ? config.stiff : undefined,
					});
				}
			),
		[cursor, pieceAnimationApi]
	);

	return (
		<S.CursorGroup style={{ transform: "translate(-50%, -50%)" }}>
			{/* <S.Plume style={{ ...plumeStyle, width: "300%", height: "300%", top: "-100%", left: "-100%" }} /> */}
			<S.Cursor style={cursorStyle}>
				<S.MainWrapper style={{ rotate: pieceStyle.additionalRotate }}>
					<S.Wrapper
						style={{
							scale: pieceStyle.wrapperScale,
							rotate: pieceStyle.wrapperRotate,
						}}>
						<S.PieceGroup>
							<S.Piece
								style={{
									x: pieceStyle.x.to((value) => value * getLogoPieceSignsByIndex(0).x),
									y: pieceStyle.y.to((value) => value * getLogoPieceSignsByIndex(0).y),
									rotate: -90,
								}}>
								<S.ImageGroup
									style={{ scale: pieceStyle.scale, x: pieceStyle.innerX, y: pieceStyle.innerY }}>
									<Image src={getVectorImageByName("SymbolicLogoPieceMaskSource")} lazy={false} />
								</S.ImageGroup>
							</S.Piece>
							<S.Piece
								style={{
									x: pieceStyle.x.to((value) => value * getLogoPieceSignsByIndex(1).x),
									y: pieceStyle.y.to((value) => value * getLogoPieceSignsByIndex(1).y),
									rotate: 0,
								}}>
								<S.ImageGroup
									style={{ scale: pieceStyle.scale, x: pieceStyle.innerX, y: pieceStyle.innerY }}>
									<Image src={getVectorImageByName("SymbolicLogoPieceMaskSource")} lazy={false} />
								</S.ImageGroup>
							</S.Piece>
						</S.PieceGroup>
						<S.PieceGroup>
							<S.Piece
								style={{
									x: pieceStyle.x.to((value) => value * getLogoPieceSignsByIndex(2).x),
									y: pieceStyle.y.to((value) => value * getLogoPieceSignsByIndex(2).y),
									rotate: 180,
								}}>
								<S.ImageGroup
									style={{ scale: pieceStyle.scale, x: pieceStyle.innerX, y: pieceStyle.innerY }}>
									<Image src={getVectorImageByName("SymbolicLogoPieceMaskSource")} lazy={false} />
								</S.ImageGroup>
							</S.Piece>
							<S.Piece
								style={{
									x: pieceStyle.x.to((value) => value * getLogoPieceSignsByIndex(3).x),
									y: pieceStyle.y.to((value) => value * getLogoPieceSignsByIndex(3).y),
									rotate: 90,
								}}>
								<S.ImageGroup
									style={{ scale: pieceStyle.scale, x: pieceStyle.innerX, y: pieceStyle.innerY }}>
									<Image src={getVectorImageByName("SymbolicLogoPieceMaskSource")} lazy={false} />
								</S.ImageGroup>
							</S.Piece>
						</S.PieceGroup>
					</S.Wrapper>
				</S.MainWrapper>
			</S.Cursor>
		</S.CursorGroup>
	);
};

const getLogoPieceSignsByIndex = (index: number) => {
	switch (index) {
		case 0:
			return { x: -1, y: -1 };
		case 1:
			return { x: 1, y: -1 };
		case 2:
			return { x: -1, y: 1 };
		default:
			return { x: 1, y: 1 };
	}
};
