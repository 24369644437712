import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { Observer } from "mobx-react-lite";
import { reaction } from "mobx";

import { Header } from "@components/containers/Header";

import { ScrollControls } from "@components/common/hoc/ScrollControls";
import { ScrollControlsMain } from "@components/common/hoc/ScrollControlsMain";

import { useBreakpoint, useGlobalStore, useResizeObserver } from "@core/hooks";
import { clientHelper } from "@core/helpers";

import * as S from "./styled";

export interface Props {
	children?: React.ReactNode;
	minScrollHeight?: string | number;
	scrollEnabled?: boolean;
	backgroundColor?: "white" | "black";
	pseudoScrollEnabled?: boolean;
}

export const Layout: React.FC<Props> = ({
	children,
	minScrollHeight,
	scrollEnabled = true,
	backgroundColor = "white",
	pseudoScrollEnabled = false,
}) => {
	const breakpoint = useBreakpoint();
	const layoutStore = useGlobalStore((store) => store.layout);
	const headerResizeObserver = useResizeObserver({ calculateSizeWithPaddings: true });

	const handleWindowResize = useCallback(() => {
		const breakpoint = clientHelper.detectBreakpoint();
		layoutStore.setBreakpoint(breakpoint);
	}, [layoutStore]);

	const checkForDampingScrollEnabled = useCallback(() => {
		const enabled = !breakpoint.range("mobile", "tablet");
		document.body.style.overflow = enabled ? "hidden" : "hidden auto";
		layoutStore.setDampingScrollEnabled(enabled);
	}, [breakpoint, layoutStore]);

	const handleMouseUp = useCallback(() => {
		layoutStore.setCursorClicked(false);
	}, [layoutStore]);

	useEffect(() => {
		window.addEventListener("resize", handleWindowResize);
		document.addEventListener("mouseup", handleMouseUp);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
			document.removeEventListener("mouseup", handleMouseUp);
		};
	}, [handleMouseUp, handleWindowResize]);

	useEffect(
		() =>
			reaction(
				() => headerResizeObserver.getSize(),
				(size) => document.body.style.setProperty("--header-height", `${size.height}px`)
			),
		[headerResizeObserver]
	);

	useEffect(() => {
		return () => layoutStore.resetSidebarOpened();
	}, [layoutStore]);

	useEffect(
		() =>
			reaction(
				() => layoutStore.breakpoint,
				() => checkForDampingScrollEnabled()
			),
		[layoutStore, checkForDampingScrollEnabled]
	);

	useLayoutEffect(() => {
		document.body.style.background = backgroundColor;
		checkForDampingScrollEnabled();
	}, [backgroundColor, checkForDampingScrollEnabled]);

	const content = <S.Content>{children}</S.Content>;

	return (
		<>
			<S.HeaderGroup ref={headerResizeObserver.ref}>
				<Header />
			</S.HeaderGroup>
			<Observer>
				{() =>
					layoutStore.dampingScrollEnabled ? (
						<ScrollControls
							minHeight={minScrollHeight}
							enabled={scrollEnabled}
							pseudoScrollEnabled={pseudoScrollEnabled}>
							{content}
						</ScrollControls>
					) : (
						content
					)
				}
			</Observer>
		</>
	);
};
