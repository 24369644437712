import { a } from "react-spring";
import styled from "styled-components";

import { Image } from "@components/common/ui/Image/styled";

export const Cursor = styled(a.div)`
	position: relative;
	z-index: 1;
	/* will-change: transform; */
`;

export const CursorGroup = styled.div`
	top: 0;
	left: 0;
	position: fixed;
	z-index: 9999;
	mix-blend-mode: difference;
	pointer-events: none;
`;

export const Plume = styled(a.div)`
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
	background: #3c3c3c;
	overflow: hidden;
	position: absolute;
	border-radius: 50%;
	will-change: transform;
`;

export const MainWrapper = styled(a.div)``;

export const Wrapper = styled(a.div)`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-radius: 50%;
	will-change: transform;
`;

export const PieceGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

export const Piece = styled(a.div)`
	width: 0.8rem;
	height: 0.8rem;
	position: relative;
`;

export const ImageGroup = styled(a.div)`
	left: 0;
	bottom: 0;
	width: 500%;
	height: 500%;
	position: absolute;
	transform-origin: left bottom;

	${Image} {
		width: 100%;
		height: 100%;
	}
`;
