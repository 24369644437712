import styled from "styled-components";
import { a } from "react-spring";

import { breakpoint } from "@styles/breakpoint";

export const HeaderGroup = styled.header`
	top: calc(var(--container-gap) + 1.35rem);
	left: var(--container-gap);
	/* padding: var(--container-gap) 0; */
	position: fixed;
	z-index: 99;
	mix-blend-mode: difference;
	width: calc(100% - var(--container-gap) * 2);

	${breakpoint("mobile", "tablet")`
		top: 0;
		z-index: 200;
		padding: calc(var(--container-gap) + 1.35rem) 0;
	`}
`;

export const SidebarGroup = styled(a.div)`
	top: 0;
	left: 0;
	z-index: 5;
	position: fixed;
	width: 100vw;
	height: 100vh;
	will-change: transform;
`;

export const Content = styled.main``;

export const FooterGroup = styled.footer`
	margin-top: 10rem;

	${breakpoint("mobile", "tablet")`
		margin-top: 5rem;
	`}
`;
