import styled from "styled-components";

import { breakpoint } from "@styles/breakpoint";

interface ContainerProps {
	$biggerGap?: boolean;
}

export const Container = styled.div<ContainerProps>`
	margin: 0 auto;
	width: var(--container-width);

	--container-width: var(
		${(props) => (props.$biggerGap ? "--container-width-smaller" : "--container-width-bigger")}
	);

	${breakpoint("mobile", "tablet")`
		--container-width: var(--container-width-bigger);
	`}
`;
