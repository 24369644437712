import { Routes as RoutesImpl, Route } from "react-router-dom";

import { Main } from "@components/pages/Main";

export const Routes: React.FC = () => {
	return (
		<RoutesImpl>
			<Route path='*' element={<Main />} />
		</RoutesImpl>
	);
};
