import { a } from "react-spring";

import styled from "styled-components";

import { Reel } from "@components/common/ordinary/Reel/styled";
import { Image } from "@components/common/ui/Image/styled";

import { verticalCenterMixin } from "@styles/mixins";
import { breakpoint } from "@styles/breakpoint";

export const PromoReel = styled(a.div)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	position: relative;
	overflow: hidden;
`;

export const Content = styled(a.div)`
	position: relative;
	z-index: 1;
`;

export const Labels = styled(a.div)`
	will-change: opacity;
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 1;
	left: 0;
	top: 0;
`;

interface LabelGroupProps {
	$position: "left" | "right";
}

export const LabelGroup = styled.div<LabelGroupProps>`
	left: ${(props) => props.$position === "right" && "90%"};
	right: ${(props) => props.$position === "left" && "90%"};

	${breakpoint("mobile", "tablet")`
		left: initial;
		right: initial;
		width: 100%;
		transform: none;
		text-align: center;
		top: ${(props: LabelGroupProps) => (props.$position === "right" ? "90%" : "initial")};
		bottom: ${(props: LabelGroupProps) => (props.$position === "left" ? "90%" : "initial")};
	`}
	${verticalCenterMixin}
`;

export const Label = styled.p`
	color: white;
	font-size: 4.2rem;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	white-space: pre;

	span {
		display: inline-block;
		will-change: transform;
	}
`;

export const LogoGroup = styled(a.div)`
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 1;
	will-change: transform;
`;

export const LogoPieces = styled.div`
	display: flex;
	flex-direction: column;
	transform: rotate(45deg);
	transform-origin: center;
`;

export const LogoPieceGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

export const LogoPiece = styled(a.div)`
	width: 100vw;
	height: 100vw;
	position: absolute;
	top: calc(50% - 50vw);
	left: calc(50% - 50vw);
	transform-origin: center;
	/* will-change: transform, opacity; */

	${Image} {
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
		}
	}
`;

export const ReelGroup = styled.div`
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
`;

export const ReelFocusContainer = styled(a.div)`
	width: 40rem;
	height: 40rem;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0);
	box-shadow: 0 0 7rem 100vw black;
	will-change: transform;
`;

export const ReelFocusContainerShadow = styled(a.div)`
	top: -2%;
	left: -2%;
	width: 104%;
	height: 104%;
	position: absolute;
	border-radius: inherit;
	box-shadow: inset 0px 0px calc(6rem * var(--focus-blur-factor))
		calc(6rem * var(--focus-intensity-factor)) black;
	will-change: box-shadow;
`;

export const ReelWrapper = styled(a.div)`
	width: 100%;
	height: 100%;
	position: relative;
	will-change: opacity;

	&:after {
		content: "";
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		position: absolute;
		background: rgba(0, 0, 0, 0.2);
	}

	${Reel} {
		height: 100%;
	}
`;

export const LogoPieceAnimatedWrapper = styled(a.div)`
	position: relative;
	width: 20rem;
	height: 20rem;
	will-change: transform;
`;

export const MouseIconGroup = styled(a.div)`
	position: absolute;
	bottom: 4rem;
	z-index: 99;

	${breakpoint("mobile", "tablet")`
		display: none;
	`}
`;
