export function isAPISupported(api: string) {
	return api in window;
}

export function isCSSSupports(propertyName: string | string[], value: string) {
	if (CSS && CSS.supports) {
		return Array.isArray(propertyName)
			? propertyName.every((propertyName) => CSS.supports(propertyName, value))
			: CSS.supports(propertyName, value);
	}

	return false;
}

export function safelyParseJSON<T extends Record<string, any> | any[]>(string: string) {
	try {
		return JSON.parse(string) as T;
	} catch (e) {
		return null;
	}
}

export function camelCaseToPascalCase(string: string) {
	const [firstLetter, ...rest] = string;
	return `${firstLetter.toUpperCase()}${rest.join("")}`;
}

export function splitIntoChars(string: string) {
	return string.split("");
}

export function splitIntoWords(string: string, includeSpaces: boolean = false) {
	return string
		.split(" ")
		.flatMap((word, index) => (includeSpaces && index !== 0 ? [" ", word] : [word]));
}

export function mergeRefs<E = any>(...refs: (React.ForwardedRef<any> | null | undefined)[]) {
	const filteredRefs = refs.filter(Boolean);

	if (filteredRefs.length === 0) {
		return undefined;
	}

	return (instance: E | null) => {
		for (const ref of filteredRefs) {
			if (typeof ref === "function") {
				ref(instance);
			} else if (ref) {
				ref.current = instance;
			}
		}
	};
}

export function createArray(arrayLength: number) {
	return Array(arrayLength).fill(null) as null[];
}

export function minAbs(...numbers: number[]) {
	let result = numbers[0];

	for (const number of numbers) {
		if (Math.abs(number) < Math.abs(result)) {
			result = number;
		}
	}

	return result;
}

export function maxAbs(...numbers: number[]) {
	let result = numbers[0];

	for (const number of numbers) {
		if (Math.abs(number) > Math.abs(result)) {
			result = number;
		}
	}

	return result;
}

export function clamp(number: number, min: number, max: number) {
	number = Math.max(number, min);
	number = Math.min(number, max);

	return number;
}

export function normalizeNumber(number: number, min: number, max: number) {
	return clamp((number - min) / (max - min) || 0, 0, 1);
}

export function lerp(x: number, y: number, t: number) {
	return (1 - t) * x + t * y;
}

export function damp(x: number, y: number, lambda: number, dt: number) {
	return lerp(x, y, 1 - Math.exp(-lambda * dt));
}

export function calculateCurrentIndex(array: any[], mainIndex: number, childIndex: number) {
	return array.slice(0, mainIndex).reduce((acc, row) => acc + row.length, 0) + childIndex;
}

export function safelyGetElementDOMRect(element: HTMLElement | SVGElement | null) {
	return (
		element?.getBoundingClientRect() || {
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			width: 0,
			height: 0,
			x: 0,
			y: 0,
		}
	);
}

export function calculateElementOffset(element: HTMLElement | SVGElement | null) {
	const DOMRect = safelyGetElementDOMRect(element);

	const body = document.body;
	const documentElement = document.documentElement;

	const scrollTop = window.pageYOffset || documentElement.scrollTop || body.scrollTop;
	const scrollLeft = window.pageXOffset || documentElement.scrollLeft || body.scrollLeft;

	const clientTop = documentElement.clientTop || body.clientTop || 0;
	const clientLeft = documentElement.clientLeft || body.clientLeft || 0;

	const top = DOMRect.top + scrollTop - clientTop;
	const left = DOMRect.left + scrollLeft - clientLeft;

	return { top: Math.round(top), left: Math.round(left) };
}

export function calculateMousePositionInsideElement(
	element: HTMLElement | SVGElement | Document | null,
	mousePosition: { x: number; y: number }
) {
	const offset =
		element === document ? { top: 0, left: 0 } : calculateElementOffset(element as HTMLElement);

	return {
		x: mousePosition.x - offset.left || 0,
		y: mousePosition.y - offset.top || 0,
	};
}

export function getMousePositionFromEvent(event: TouchEvent | MouseEvent) {
	const hasTouches = "touches" in event;
	// @ts-ignore
	const targetProperty = hasTouches ? event.touches[0] : event;

	return {
		x: targetProperty.pageX || 0,
		y: targetProperty.pageY || 0,
	};
}

export function vectorLength(vector: number[], center?: number[]) {
	const sum = vector.reduce(
		(acc, number, index) => acc + Math.pow(number - (center ? center[index] : 0), 2),
		0
	);
	return Math.sqrt(sum);
}

export function normalizePosition(
	position: { x: number; y: number },
	bounds: { width: number; height: number }
) {
	const normalized = {
		x: position.x / Math.max(bounds.width, 0.1),
		y: position.y / Math.max(bounds.height, 0.1),
	};
	return {
		x: clamp((normalized.x - 0.5) * 2, -1, 1),
		y: clamp((normalized.y - 0.5) * 2, -1, 1),
	};
}

export function range(offset: number, from: number, distance: number, margin: number = 0) {
	const start = from - margin;
	const end = start + distance + margin * 2;
	return offset < start ? 0 : offset > end ? 1 : (offset - start) / (end - start);
}

export function visible(offset: number, from: number, distance: number, margin: number = 0) {
	const start = from - margin;
	const end = start + distance + margin * 2;
	return offset >= start && offset <= end;
}

const app = document.getElementById("app");

export function scrollTo(scroll: number) {
	if (app) {
		app.scrollTop = scroll;
	}
}
