import styled from "styled-components";

import { Container as ContainerImpl } from "@components/common/ui/Container/styled";
import { Reel } from "@components/common/ordinary/Reel/styled";

import { breakpoint } from "@styles/breakpoint";
import { animated } from "react-spring";

export const PromoBanner = styled.div`
	width: 100%;
	transition: transform 1s ease, opacity 0.6s ease;
	opacity: 0;
	/* transform: translateY(10vh); */

	${breakpoint("mobile", "tablet")`
      background: none;
	`}

	&.visible {
		opacity: 1;
		transform: translateY(0);
	}
`;

export const Shadow = styled(animated.div)<{ $onBottom?: boolean }>`
	left: 0;
	top: ${({ $onBottom }) => ($onBottom ? "calc(100vh - var(--size))" : "0")};
	width: 100%;
	pointer-events: none;
	height: var(--size);
	position: absolute;
	background: linear-gradient(
		${({ $onBottom }) => ($onBottom ? "0deg" : "180deg")},
		black 35%,
		transparent
	);
	z-index: 99;
	transition: opacity 0.6s ease;
	opacity: 0;
	--size: min(20vh, 15rem);

	&.visible {
		opacity: 1;
	}

	${breakpoint("mobile", "tablet")`
      top: ${({ $onBottom }: { $onBottom?: boolean }) =>
							$onBottom ? "calc(100vh - var(--size))" : "0"};
      bottom: ${({ $onBottom }: { $onBottom?: boolean }) => $onBottom && "initial"};
		position: sticky;
		--size: 12rem;
	`}
`;

export const ScrollGroup = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	padding: 20vh 0 50vh;

	${breakpoint("mobile", "tablet")`
		margin-top: 0;
		padding: 20vh 0 50vh;
	`}
`;

export const ReelGroup = styled.div`
	display: none;
	width: 100vw;
	/* margin-top: var(--header-height); */
	/* margin-left: calc(var(--container-gap) * -1); */

	${Reel} {
		/* height: auto; */
	}

	${breakpoint("mobile", "tablet")`
		top: 0;
		left: 0;
		opacity: .8;
		height: 100vh;
		display: block;
		pointer-events:none;

		${Reel} {
			height: 100%;
		}
   `}
`;

export const Container = styled(ContainerImpl)`
	display: flex;
	min-height: 100%;
	align-items: center;
	justify-content: center;

	${breakpoint("mobile", "tablet")`
      display: block;
		margin-top: -24rem;
   `}
`;

export const Title = styled.div`
	font-size: 8rem;
	font-weight: 500;
	color: white;
	line-height: 100%;
	text-align: center;

	/* p > span {
		overflow: hidden;
	}

	span {
		white-space: pre;
		display: inline-block;
	} */

	${breakpoint("mobile", "tablet")`
		font-size: 3rem;
		line-height: 110%;
   `}
`;

export const Content = styled.div`
	${breakpoint("mobile", "tablet")`
      display: flex;
      flex-direction: column;
   `}
`;

export const Text = styled.div`
	position: relative;

	${breakpoint("mobile", "tablet")`
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: auto;
   `}
`;

export const AnimatedWord = styled.span`
	transition: transform ease, opacity ease;
	will-change: transform;
`;

export const GetReadyButtonGroup = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 4rem;
	transition: transform ease, opacity ease;
	will-change: transform;

	${breakpoint("mobile", "tablet")`
      margin-top: 4rem;
   `}
`;

export const GetReadyButton = styled.button`
	color: black;
	padding: 2rem 4rem;
	font-size: 2.6rem;
	/* font-weight: 600; */
	background: white;
	border-radius: 90rem;

	${breakpoint("mobile", "tablet")`
      padding: 1.8rem 3.6rem;
      font-size: 2.4rem;
   `}
`;
