import React, { useCallback, useRef } from "react";
import { a, useSpring } from "react-spring";

import { useBreakpoint, useResizeObserver } from "@core/hooks";
import { calculateMousePositionInsideElement, normalizePosition } from "@core/utils";

export interface Props extends React.ComponentProps<"div"> {
	areaOffset?: number;
	enabled?: boolean;
}

export const CursorFollower: React.FC<Props> = ({
	children,
	enabled = true,
	areaOffset = 10,
	...rest
}) => {
	const breakpoint = useBreakpoint();
	const containerRef = useRef<HTMLDivElement>(null);
	const resizeObserver = useResizeObserver({ ref: containerRef, calculateSizeWithPaddings: true });
	const [wrapperStyle, wrapperAnimationApi] = useSpring(() => ({ x: 0, y: 0 }));

	const handleMouseMove = useCallback(
		(event: React.MouseEvent) => {
			if (!enabled || breakpoint.range("mobile", "tablet")) return;

			const { pageX: x, pageY: y } = event;
			const position = calculateMousePositionInsideElement(containerRef.current as HTMLDivElement, {
				x,
				y,
			});
			const normalized = normalizePosition(
				{ x: Math.max(0, position.x), y: Math.max(0, position.y) },
				resizeObserver.getSize()
			);

			wrapperAnimationApi.start({ x: normalized.x * areaOffset, y: normalized.y * areaOffset });
		},
		[enabled, breakpoint, resizeObserver, wrapperAnimationApi, areaOffset]
	);

	const handleMouseLeave = useCallback(() => {
		wrapperAnimationApi.start({ x: 0, y: 0 });
	}, [wrapperAnimationApi]);

	return (
		<div
			{...rest}
			ref={containerRef}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
			style={{ padding: areaOffset, margin: -areaOffset }}>
			<a.div style={{ ...wrapperStyle, willChange: enabled ? "transform" : "auto" }}>{children}</a.div>
		</div>
	);
};
