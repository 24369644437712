import { memo, useCallback } from "react";
import { observer, Observer } from "mobx-react-lite";
import { a } from "react-spring";

import { Layout } from "@components/containers/Layout";
import { PromoReel } from "@components/containers/PromoReel";
import { PromoBanner } from "@components/containers/PromoBanner";
import { FeedbackOverlay } from "@components/containers/FeedbackOverlay";
import { SimpleAnimatedReel } from "@components/containers/SimpleAnimatedReel";
import { context } from "@components/common/hoc/ScrollControls";

import { useBreakpoint, useGlobalStore, useLocalStore, useScroll } from "@core/hooks";

export const Main: React.FC = () => {
	const breakpoint = useBreakpoint();
	const localStore = useLocalStore({ animationEnded: false });

	const handleAnimationEnded = useCallback(() => {
		localStore.setAnimationEnded(true);
	}, [localStore]);

	return (
		<>
			<FeedbackOverlay />
			<Observer>
				{() => (
					<Layout
						minScrollHeight='200vh'
						backgroundColor='black'
						scrollEnabled={localStore.animationEnded}
						pseudoScrollEnabled>
						<Observer>
							{() =>
								breakpoint.range("mobile", "tablet") ? null : (
									<PromoReel onAnimationEnded={handleAnimationEnded} />
								)
							}
						</Observer>
						<Content />
					</Layout>
				)}
			</Observer>
		</>
	);
};

const Content: React.FC = observer(() => {
	const scroll = useScroll(context, true);

	return scroll.store.pseudoScrollEnabled ? (
		<a.div
			style={{
				top: 0,
				left: 0,
				width: "100%",
				position: "absolute",
				y: scroll.animated.pseudoScrollEnabled.to((value) => (value ? "200vh" : "0")),
			}}>
			<PromoBanner />
		</a.div>
	) : (
		<PromoBanner />
	);
});
