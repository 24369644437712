import React from "react";

import { Image } from "@components/common/ui/Image";

import { useCursor } from "@core/hooks";
import { useGlobalStore } from "@core/hooks";

import { getVectorImageByName } from "@assets/images";

import * as S from "./styled";

export const Header: React.FC = () => {
	const cursor = useCursor();
	const layoutStore = useGlobalStore((store) => store.layout);

	return (
		<S.Header>
			<S.Logo>
				<Image src={getVectorImageByName("LogoSource")} lazy={false} />
			</S.Logo>
			<S.SymbolicLogo {...cursor.bind()} onClick={() => layoutStore.setFeedbackOverlayOpened(true)}>
				<Image src={getVectorImageByName("SymbolicLogoSource")} lazy={false} />
			</S.SymbolicLogo>
		</S.Header>
	);
};
