import { a } from "react-spring";
import styled from "styled-components";
import Grid from "styled-components-grid";

import { Container as ContainerImpl } from "@components/common/ui/Container/styled";

import { breakpoint } from "@styles/breakpoint";

export const FeedbackOverlay = styled(a.div)`
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	height: 100vh;
	position: fixed;
	overflow: hidden auto;
	will-change: transform;
`;

export const ContentGroup = styled.div`
	z-index: 1;
	width: 100%;
	/* padding-top: var(--header-height); */
	min-height: 100vh;
	position: relative;
`;

export const Background = styled(a.div)`
	top: 0;
	left: 0;
	z-index: 0;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 998;
	pointer-events: none;
	background: rgba(0, 0, 0, 0.5);
`;

export const Content = styled.div`
	z-index: 1;
	width: 100%;
	padding: 10rem 0;
	/* min-height: calc(100vh - var(--header-height)); */
	min-height: 100vh;
	display: flex;
	background: black;
	position: relative;
	align-items: center;
	justify-content: center;

	${breakpoint("mobile", "tablet")`
		padding: 6.8rem 0;
	`}
`;

export const Head = styled.div`
	text-align: center;

	${breakpoint("mobile", "tablet")`
		text-align: left;
	`}
`;

export const Title = styled.p`
	font-size: 3.8rem;
	font-weight: 600;
	color: white;

	${breakpoint("mobile", "tablet")`
      font-size: 3rem;
   `}
`;

export const Subtitle = styled.p`
	font-size: 2.8rem;
	line-height: 140%;
	margin-top: 1.4rem;
	color: white;

	${breakpoint("mobile", "tablet")`
      font-size: 2rem;

		br {
			display: none;
		}
   `}
`;

export const Notifications = styled.div`
	width: 100%;
	position: fixed;
	padding: 2rem;
	bottom: 0;
	z-index: 9999;
	text-align: center;
`;

export const Notification = styled.button`
	padding: 1.4rem 1.8rem;
	color: black;
	font-weight: 500;
	font-size: 1.6rem;
	background: #4eff4e;
	border-radius: 0.4rem;
	border: 1px solid #2eec2e;
`;

export const Input = styled.input`
	width: 100%;
	color: white;
	padding: 1.4rem 0;
	font-size: 2.8rem;
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
	transition: border-color 0.3s ease;

	&::placeholder {
		color: rgba(255, 255, 255, 0.4);
	}

	&:focus {
		border-bottom-color: white;
	}

	&:disabled {
		opacity: 0.8;
	}

	${breakpoint("mobile", "tablet")`
		font-size: 2.2rem;
		padding: 1rem;
	`}
`;

export const Textarea = styled(Input)`
	max-width: 100%;
	line-height: 140%;
	min-height: calc(2em + 2.8rem);
	max-height: 10em;
	resize: vertical;

	&:disabled {
		opacity: 0.8;
	}
`;

const INPUT_PADDING = "1.6rem";

export const InputGroup = styled(Grid.Unit)`
	padding: 0 calc(${INPUT_PADDING} / 2);

	${breakpoint("mobile", "tablet")`
		flex-basis: 100%;
		max-width: 100%;
		width: 100%;
	`}
`;

export const InputRow = styled(Grid)`
	margin: 0 calc(-${INPUT_PADDING} / 2);
`;

export const FormContent = styled.div`
	margin: 8rem 0;

	${InputRow}:not(:nth-child(1)) {
		margin-top: 2rem;
	}

	${breakpoint("mobile", "tablet")`
		margin: 4rem 0;

		${InputRow}, ${InputRow}:nth-child(1) > ${InputGroup}:nth-child(1) {
			margin-top: 0!important;
		}

		${InputGroup} {
			margin-top: 2rem;
		}
	`}
`;

export const GetReadyButtonGroup = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;

	${breakpoint("mobile", "tablet")`
      justify-content: flex-start;
   `}
`;

export const GetReadyButton = styled.button`
	color: black;
	padding: 2rem 4rem;
	font-size: 2.6rem;
	background: white;
	border-radius: 90rem;

	${breakpoint("mobile", "tablet")`
      padding: 1.8rem 3.6rem;
      font-size: 2.2rem;
   `}

	&:disabled {
		opacity: 0.8;
	}
`;

export const CloseButtonGroup = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	position: fixed;
`;

export const CloseButtonContainer = styled(ContainerImpl)`
	position: relative;
`;

export const Container = styled(ContainerImpl)`
	width: auto;
	max-width: var(--container-width);
`;

export const CloseButton = styled.button`
	top: var(--container-gap);
	right: -0.8rem;
	box-sizing: content-box;
	position: absolute;
	padding: 0.8rem;
	width: 2.4rem;
	height: 2.4rem;
`;
