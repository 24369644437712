import styled from "styled-components";

import { Image } from "@components/common/ui/Image/styled";

import { breakpoint } from "@styles/breakpoint";

export const Header = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	mix-blend-mode: difference;
	justify-content: space-between;
	height: 1px;
	width: 100%;

	${breakpoint("mobile", "tablet")`
		height: auto;
	`}
`;

export const Logo = styled.div`
	height: 2rem;

	${Image} {
		height: 100%;
	}
`;

export const SymbolicLogo = styled.div`
	width: 3.6rem;
	height: 3.6rem;
`;
