import { useEffect } from "react";
import { useLocation } from "react-router";

import { scrollTo } from "@core/utils";

export const ScrollToTop: React.FC = () => {
	const location = useLocation();

	useEffect(() => {
		scrollTo(0);
	}, [location]);

	return null;
};
