import { Observer } from "mobx-react-lite";

import { Routes } from "@components/routes";

// import { Loader } from "@components/containers/Loader";

import { ScrollToTop } from "@components/common/simple/ScrollToTop";

import { Cursor } from "@components/common/ui/Cursor";

import { useBreakpoint } from "@core/hooks";

export const App: React.FC = () => {
	const breakpoint = useBreakpoint();

	return (
		<>
			<Routes />
			{/* <Loader /> */}
			<ScrollToTop />
			<Observer>{() => (breakpoint.range("laptop") ? <Cursor /> : null)}</Observer>
		</>
	);
};
