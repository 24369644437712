import React, { useCallback, useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import { CursorFollower } from "@components/common/hoc/CursorFollower";

import { Reel } from "@components/common/ordinary/Reel";

import { useBreakpoint, useCursor, useGlobalStore, useScroll } from "@core/hooks";
import { splitIntoWords, calculateCurrentIndex, clamp } from "@core/utils";
import { context } from "@components/common/hoc/ScrollControls";

import * as S from "./styled";
import { observer } from "mobx-react-lite";

export const PromoBanner: React.FC = observer(() => {
	const cursor = useCursor();
	const scroll = useScroll(context, true);
	const breakpoint = useBreakpoint();
	const layoutStore = useGlobalStore((store) => store.layout);
	const { ref, inView } = useInView({ triggerOnce: true });
	const [visible, setVisible] = useState(false);

	const handleGetReadyButtonClick = useCallback(() => {
		layoutStore.setFeedbackOverlayOpened(true);
	}, [layoutStore]);

	useEffect(() => {
		if (!scroll.store.pseudoScrollEnabled) {
			setTimeout(() => setVisible(true), 10);
		}
	}, [scroll.store.pseudoScrollEnabled]);

	return (
		<>
			<S.ReelGroup className='reel'>
				<Reel />
			</S.ReelGroup>
			<S.PromoBanner ref={ref} className={visible ? "visible" : undefined}>
				<S.Shadow
					style={{
						y: breakpoint.mobile()
							? 0
							: scroll.animated.offset.to((value) =>
									Math.max(
										0,
										value * (scroll.store.pages - 1) * scroll.store.containerHeight - window.innerHeight
									)
							  ),
					}}
					className={visible ? "visible" : undefined}
				/>
				<S.Shadow
					$onBottom
					className={visible ? "visible" : undefined}
					style={{
						y: breakpoint.mobile()
							? 0
							: scroll.animated.offset.to((value) =>
									Math.max(
										0,
										value * (scroll.store.pages - 1) * scroll.store.containerHeight - window.innerHeight
									)
							  ),
					}}
				/>
				<S.Container>
					<S.Content>
						<S.ScrollGroup>
							<S.Text>
								<div>
									{[
										"3D Design",
										"CGI Campaigns",
										"Motion Graphics",
										"Virtual Production",
										"Interactive Experiences",
										"Sound Design & FX",
										"Real-time Engines",
										"Digital Fashion",
										"Creative AI",
									].map((text, index) => (
										<S.Title key={index}>{text}</S.Title>
									))}
									{/* <S.Title>
							</S.Title>
							<S.Title>
								{titleWords.map((row, rowIndex) => (
									<p key={rowIndex}>
										{row.map((word, wordIndex) => (
											<span key={wordIndex}>
												<S.AnimatedWord
													style={generateTitleWordStyles(
														calculateCurrentIndex(titleWords, rowIndex, wordIndex),
														inView
													)}>
													{word}
												</S.AnimatedWord>
											</span>
										))}
									</p>
								))}
							</S.Title> */}
									<S.GetReadyButtonGroup style={generateButtonGroupStyles(true)}>
										<CursorFollower areaOffset={20}>
											<S.GetReadyButton {...cursor.bind()} onClick={handleGetReadyButtonClick}>
												Get Ready
											</S.GetReadyButton>
										</CursorFollower>
									</S.GetReadyButtonGroup>
								</div>
							</S.Text>
						</S.ScrollGroup>
					</S.Content>
				</S.Container>
			</S.PromoBanner>
		</>
	);
});

const titleWords = ["Guiding your brand", "into the metaverse"].map((row) =>
	splitIntoWords(row, true)
);

const generateTitleWordStyles = (index: number, active: boolean = false): React.CSSProperties => ({
	opacity: active ? 1 : 0,
	transform: `translate3d(0, ${active ? 0 : -100}%, 0)`,
	transitionDelay: `${index * 30}ms`,
	transitionDuration: "500ms",
});

const generateButtonGroupStyles = (active: boolean = false): React.CSSProperties => ({
	opacity: active ? 1 : 0,
	transform: `translate3d(0, ${active ? 0 : 2}rem, 0)`,
	transitionDuration: "1000ms",
});
