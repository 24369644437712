import { useContext } from "react";
import { SpringValue } from "react-spring";

export function useScroll<T>(context: React.Context<T>, safe?: boolean): T {
	const ctx = useContext(context as any);

	if (ctx) {
		return ctx as T;
	}

	return safe
		? // @ts-ignore
		  ({
				store: { pages: 0, containerHeight: 0, pseudoScrollEnabled: false },
				animated: { offset: new SpringValue(0), pseudoScrollEnabled: new SpringValue(0) },
		  } as T)
		: (null as unknown as T);
}
