import { useCallback, useEffect, memo } from "react";
import { a, useSpring, useSprings } from "react-spring";
import { observer, Observer } from "mobx-react-lite";

import { Reel } from "@components/common/ordinary/Reel";
import { context } from "@components/common/hoc/ScrollControls";

import { Image } from "@components/common/ui/Image";
import { MouseIcon } from "@components/common/ui/MouseIcon";

import { useScroll, useGlobalStore } from "@core/hooks";
import { animationHelper } from "@core/helpers";
import { normalizeNumber, range } from "@core/utils";

import { getVectorImageByName } from "@assets/images";

import * as S from "./styled";

export interface Props {
	onAnimationEnded: () => void;
}

export const PromoReel: React.FC<Props> = observer(({ onAnimationEnded }) => {
	const scroll = useScroll(context, true);
	const layoutStore = useGlobalStore((store) => store.layout);
	const [pieceStyle, pieceAnimationApi] = useSpring(() => ({
		translate: 0,
		opacity: 0,
	}));
	const [labelStyle, labelAnimationApi] = useSprings(4, (index) => ({
		y: index % 2 === 0 ? "-100%" : "100%",
		opacity: 0,
	}));

	const animate = useCallback(async () => {
		await animationHelper.resolveSpringAnimation(pieceAnimationApi, {
			translate: ANIMATED_PIECES_OFFSET,
			opacity: 1,
		});
		await animationHelper.resolveSpringAnimation(labelAnimationApi, (index) => ({
			y: "0",
			opacity: 1,
			delay: index * 75,
		}));
		onAnimationEnded();
	}, [labelAnimationApi, onAnimationEnded, pieceAnimationApi]);

	const toRange = useCallback(
		(value: number) => {
			return range(value, 0, 1 / scroll.store.pages);
		},
		[scroll]
	);

	const toPseudoRange = useCallback(
		(value: number) => {
			return scroll.animated.pseudoScrollEnabled.get() ? toRange(value) : 1;
		},
		[scroll.animated.pseudoScrollEnabled, toRange]
	);

	useEffect(() => {
		animate();
	}, [animate]);

	return (
		<S.PromoReel
			style={{
				y: scroll.store.pseudoScrollEnabled
					? scroll.animated.offset.to((value) =>
							value > 0 ? value * (scroll.store.pages - 1) * scroll.store.containerHeight : 0
					  )
					: 0,
			}}>
			{scroll.store.pseudoScrollEnabled ? (
				<S.Content style={{ opacity: scroll.animated.pseudoScrollEnabled }}>
					<S.LogoGroup
						style={{
							rotate: scroll.animated.offset.to(toRange).to((value) => -45 * value),
							scale: scroll.animated.offset
								.to(toRange)
								.to((value) => 1 + getLogoNeededScale(scroll.store.containerHeight) * value),
						}}>
						<S.LogoPieces>
							<S.LogoPieceGroup>
								<S.LogoPieceAnimatedWrapper
									style={{
										x: scroll.animated.offset
											.to(toRange)
											.to((value) => FILLED_PIECES_OFFSET * value * getLogoPieceSignsByIndex(0).x),
										y: scroll.animated.offset
											.to(toRange)
											.to((value) => FILLED_PIECES_OFFSET * value * getLogoPieceSignsByIndex(0).y),
									}}>
									<S.LogoPiece
										style={{
											rotate: -90,
											opacity: pieceStyle.opacity,
											x: pieceStyle.translate.to((value) => value * getLogoPieceSignsByIndex(0).x),
											y: pieceStyle.translate.to((value) => value * getLogoPieceSignsByIndex(0).y),
											scale: 200 / window.innerWidth,
										}}>
										<Image src={getVectorImageByName("SymbolicLogoPieceMaskSource")} lazy={false} />
									</S.LogoPiece>
								</S.LogoPieceAnimatedWrapper>
								<S.LogoPieceAnimatedWrapper
									style={{
										x: scroll.animated.offset
											.to(toRange)
											.to((value) => FILLED_PIECES_OFFSET * value * getLogoPieceSignsByIndex(1).x),
										y: scroll.animated.offset
											.to(toRange)
											.to((value) => FILLED_PIECES_OFFSET * value * getLogoPieceSignsByIndex(1).y),
									}}>
									<S.LogoPiece
										style={{
											rotate: 0,
											opacity: pieceStyle.opacity,
											x: pieceStyle.translate.to((value) => value * getLogoPieceSignsByIndex(1).x),
											y: pieceStyle.translate.to((value) => value * getLogoPieceSignsByIndex(1).y),
											scale: 200 / window.innerWidth,
										}}>
										<Image src={getVectorImageByName("SymbolicLogoPieceMaskSource")} lazy={false} />
									</S.LogoPiece>
								</S.LogoPieceAnimatedWrapper>
							</S.LogoPieceGroup>
							<S.LogoPieceGroup>
								<S.LogoPieceAnimatedWrapper
									style={{
										x: scroll.animated.offset
											.to(toRange)
											.to((value) => FILLED_PIECES_OFFSET * value * getLogoPieceSignsByIndex(2).x),
										y: scroll.animated.offset
											.to(toRange)
											.to((value) => FILLED_PIECES_OFFSET * value * getLogoPieceSignsByIndex(2).y),
									}}>
									<S.LogoPiece
										style={{
											rotate: -180,
											opacity: pieceStyle.opacity,
											x: pieceStyle.translate.to((value) => value * getLogoPieceSignsByIndex(2).x),
											y: pieceStyle.translate.to((value) => value * getLogoPieceSignsByIndex(2).y),
											scale: 200 / window.innerWidth,
										}}>
										<Image src={getVectorImageByName("SymbolicLogoPieceMaskSource")} lazy={false} />
									</S.LogoPiece>
								</S.LogoPieceAnimatedWrapper>
								<S.LogoPieceAnimatedWrapper
									style={{
										x: scroll.animated.offset
											.to(toRange)
											.to((value) => FILLED_PIECES_OFFSET * value * getLogoPieceSignsByIndex(3).x),
										y: scroll.animated.offset
											.to(toRange)
											.to((value) => FILLED_PIECES_OFFSET * value * getLogoPieceSignsByIndex(3).y),
									}}>
									<S.LogoPiece
										style={{
											rotate: 90,
											opacity: pieceStyle.opacity,
											x: pieceStyle.translate.to((value) => value * getLogoPieceSignsByIndex(3).x),
											y: pieceStyle.translate.to((value) => value * getLogoPieceSignsByIndex(3).y),
											scale: 200 / window.innerWidth,
										}}>
										<Image src={getVectorImageByName("SymbolicLogoPieceMaskSource")} lazy={false} />
									</S.LogoPiece>
								</S.LogoPieceAnimatedWrapper>
							</S.LogoPieceGroup>
						</S.LogoPieces>
					</S.LogoGroup>
					<S.Labels
						style={{
							opacity: scroll.animated.offset
								.to(toRange)
								.to((value) => 1 - normalizeNumber(value, 0, 0.1)),
						}}>
						<S.LabelGroup $position='left'>
							<S.Label>
								<a.span style={labelStyle[0]}>we</a.span> <a.span style={labelStyle[1]}>are</a.span>
							</S.Label>
						</S.LabelGroup>
						<S.LabelGroup $position='right'>
							<S.Label>
								<a.span style={labelStyle[2]}>cro</a.span>
								<a.span style={labelStyle[3]}>ss</a.span>
							</S.Label>
						</S.LabelGroup>
					</S.Labels>
				</S.Content>
			) : null}
			{layoutStore.dampingScrollEnabled ? (
				<S.ReelGroup>
					<S.ReelWrapper
						style={{
							opacity: scroll.animated.offset.to(toPseudoRange),
							// scale: scroll.animated.offset
							// 	.to(toPseudoRange)
							// 	.to((value) => 1 + getLogoNeededScale(scroll.store.containerHeight) * (1 - value)),
							// filter: scroll.animated.offset
							// 	.to(toPseudoRange)
							// 	.to((value) => `blur(${40 * (1 - value)}px)`),
						}}>
						<Reel />
					</S.ReelWrapper>
					{scroll.store.pseudoScrollEnabled ? (
						<S.ReelFocusContainer
							style={{
								scale: scroll.animated.offset
									.to(toRange)
									.to((value) => 1 + getLogoNeededScale(scroll.store.containerHeight) * value),
								opacity: scroll.animated.pseudoScrollEnabled,
							}}>
							<S.ReelFocusContainerShadow
								style={{
									boxShadow: scroll.animated.offset.to(toRange).to(
										(value) => `inset 0px 0px calc(6rem * ${1 - value})
		calc(6rem * ${1 - value}) black`
									),
								}}
							/>
						</S.ReelFocusContainer>
					) : null}
				</S.ReelGroup>
			) : null}
			{scroll.store.pseudoScrollEnabled ? (
				<S.MouseIconGroup
					style={{
						opacity: scroll.animated.offset
							.to(toRange)
							.to((value) =>
								scroll.animated.pseudoScrollEnabled.get() ? 1 - normalizeNumber(value, 0, 0.1) : 0
							),
					}}>
					<MouseIcon />
				</S.MouseIconGroup>
			) : null}
		</S.PromoReel>
	);
});

const getLogoPieceSignsByIndex = (index: number) => {
	switch (index) {
		case 0:
			return { x: -1, y: -1 };
		case 1:
			return { x: 1, y: -1 };
		case 2:
			return { x: -1, y: 1 };
		default:
			return { x: 1, y: 1 };
	}
};

const getLogoNeededScale = (vpHeight: number) => {
	return Math.max(vpHeight / ((ANIMATED_PIECES_OFFSET + FILLED_PIECES_OFFSET) * 2) - 0.9, 0);
};

const ANIMATED_PIECES_OFFSET = 10;
const FILLED_PIECES_OFFSET = 50;

// const logoPiceAnimationVariants = {
// 	initial: {
// 		x: 0,
// 		y: 0,
// 		opacity: 0,
// 		transition: { type: "spring", tension: 170, friction: 26, damping: 15 },
// 	},
// 	active: (index: number) => ({
// 		x: 10 * getLogoPieceSignsByIndex(index).x,
// 		y: 10 * getLogoPieceSignsByIndex(index).y,
// 		opacity: 1,
// 		transition: { type: "spring", tension: 170, friction: 26, damping: 15 },
// 	}),
// };

// const labelAnimationVariants = {
// 	initial: (index: number) => ({
// 		y: index % 2 === 0 ? "-100%" : "100%",
// 		opacity: 0,
// 		transition: { type: "spring", stiffness: 200, damping: 30 },
// 	}),
// 	active: (index: number) => ({
// 		y: "0%",
// 		opacity: 1,
// 		transition: { type: "spring", stiffness: 200, damping: 30, delay: index * 0.075 },
// 	}),
// };
