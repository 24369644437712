import { Observer } from "mobx-react-lite";

import { useGlobalStore } from "@core/hooks";

import { getVideoByName } from "@assets/videos";
import { getRasterImageByName } from "@assets/images";

import * as S from "./styled";

export const Reel: React.FC = () => {
	const appStore = useGlobalStore((store) => store.app);

	return (
		<Observer>
			{() => (
				<S.Reel
					muted
					loop
					autoPlay
					playsInline
					controls={false}
					poster={getRasterImageByName("jpg", "ReelPosterSource")}>
					<source src={getVideoByName("ReelSource")} type='video/mp4' />
				</S.Reel>
			)}
		</Observer>
	);
};
