import { schemaHelper, clientHelper } from "@core/helpers";

export const layout = schemaHelper.generateStoreSchema({
	scrollEnabled: true,
	sidebarOpened: false,
	cursorClicked: false,
	dampingScrollEnabled: false,
	feedbackOverlayOpened: false,
	breakpoint: clientHelper.detectBreakpoint(),
	cursorState: "default" as "pointer" | "default",
	toggleSidebarOpened: function () {
		this.sidebarOpened = !this.sidebarOpened;
	},
});
