import { css } from "styled-components";

import { getFontSourceByName } from "@assets/fonts";

interface FontConfig {
	name: string;
	folderName: string;
}

const graphikConfig: FontConfig = {
	name: "Graphik",
	folderName: "Graphik",
};

interface FontFaceFactoryOptions extends FontConfig {
	sources: Record<string, string>;
	weight?: string | number;
	style?: string;
}

function fontFaceFactory({
	name,
	sources,
	weight = 600,
	style = "normal",
}: FontFaceFactoryOptions) {
	return css`
		@font-face {
			font-display: swap;
			font-style: normal;
			font-weight: ${weight};
			font-family: "${name}";
			font-style: ${style};
			src: ${Object.entries(sources)
				.map(([format, source]) => `url(${source}) format("${format}")`)
				.join(",")};
		}
	`;
}

export const fontsStyle = css`
	${fontFaceFactory({
		...graphikConfig,
		weight: 600,
		sources: {
			truetype: getFontSourceByName("Graphik", "Semibold"),
		},
	})}
	${fontFaceFactory({
		...graphikConfig,
		weight: 500,
		sources: {
			truetype: getFontSourceByName("Graphik", "Medium"),
		},
	})}
	${fontFaceFactory({
		...graphikConfig,
		weight: 400,
		sources: {
			truetype: getFontSourceByName("Graphik", "Regular"),
		},
	})}
`;
