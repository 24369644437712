import React, { useCallback } from "react";

import { useGlobalStore } from "@core/hooks";

export function useCursor() {
	const layoutStore = useGlobalStore((store) => store.layout);

	const getState = useCallback(() => {
		return layoutStore.cursorState;
	}, [layoutStore]);

	const getCursorClicked = useCallback(() => {
		return layoutStore.cursorClicked;
	}, [layoutStore]);

	const handleMouseEnter = useCallback(
		(event: React.UIEvent) => {
			layoutStore.setCursorState("pointer");
		},
		[layoutStore]
	);

	const handleMouseLeave = useCallback(
		(event: React.UIEvent) => {
			layoutStore.setCursorState("default");
		},
		[layoutStore]
	);

	const handleMouseDown = useCallback(() => {
		layoutStore.setCursorClicked(true);
	}, [layoutStore]);

	const bind = useCallback(() => {
		return {
			onMouseEnter: handleMouseEnter,
			onMouseLeave: handleMouseLeave,
			onMouseDown: handleMouseDown,
		};
	}, [handleMouseDown, handleMouseEnter, handleMouseLeave]);

	return {
		bind,
		getState,
		getCursorClicked,
	};
}
