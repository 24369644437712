import React, { useCallback, useEffect, useRef } from "react";
import { useFormix } from "react-formix";
import { useSpring } from "react-spring";
import { observer } from "mobx-react-lite";
import { reaction } from "mobx";
import axios from "axios";

import { CursorFollower } from "@components/common/hoc/CursorFollower";

import { useCursor, useGlobalStore, useLocalStore } from "@core/hooks";

import { getVectorImageByName } from "@assets/images";

import * as S from "./styled";

export const FeedbackOverlay: React.FC = observer(() => {
	const cursor = useCursor();
	const localStore = useLocalStore({
		pending: false,
		notificationVisible: false,
	});
	const formix = useFormix({ name: "", phone: "", email: "", brand: "", details: "" });
	const [{ value: animationState }, animationStateApi] = useSpring(() => ({ value: 0 }));
	const feedbackOverlayRef = useRef<HTMLDivElement>(null);
	const layoutStore = useGlobalStore((store) => store.layout);

	const handleCloseButtonClick = useCallback(() => {
		layoutStore.setFeedbackOverlayOpened(false);
	}, [layoutStore]);

	const handleFormSubmit = useCallback(
		async (event: React.FormEvent) => {
			event.preventDefault();

			localStore.setPending(true);

			try {
				await axios.post("/submit-your-request.php", formix.getValues(), {
					headers: { "Content-Type": "application/x-www-form-urlencoded" },
				});
				handleCloseButtonClick();
				localStore.setNotificationVisible(true);
				formix.setValues({ name: "", phone: "", email: "", brand: "", details: "" });
				setTimeout(() => localStore.setNotificationVisible(false), 5_000);
			} catch (error) {
			} finally {
				localStore.setPending(false);
			}
		},
		[formix, handleCloseButtonClick, localStore]
	);

	useEffect(
		() =>
			reaction(
				() => layoutStore.feedbackOverlayOpened,
				(opened) => {
					if (opened) {
						const feedbackOverlay = feedbackOverlayRef.current!;
						feedbackOverlay.scrollTop = 0;
					}

					animationStateApi.start({ value: opened ? 1 : 0 });
				}
			),
		[animationStateApi, layoutStore]
	);

	return (
		<>
			{localStore.notificationVisible ? (
				<S.Notifications>
					<S.Notification onClick={() => localStore.setNotificationVisible(false)}>
						Mail successfully sent
					</S.Notification>
				</S.Notifications>
			) : null}
			<S.Background style={{ opacity: animationState }} />
			<S.FeedbackOverlay
				ref={feedbackOverlayRef}
				style={{ y: animationState.to((value) => `${(1 - value) * 100}vh`) }}>
				<S.ContentGroup>
					<S.Content>
						<S.CloseButtonGroup>
							<S.CloseButtonContainer>
								<S.CloseButton {...cursor.bind()} onClick={handleCloseButtonClick}>
									{getVectorImageByName("CloseIcon")}
								</S.CloseButton>
							</S.CloseButtonContainer>
						</S.CloseButtonGroup>
						<S.Container>
							<S.Head>
								<S.Title>Have a project?</S.Title>
								<S.Subtitle>
									Please let us know a few more details to get in touch <br />
									with the Cross team.
								</S.Subtitle>
							</S.Head>
							<form onSubmit={handleFormSubmit}>
								<S.FormContent>
									<S.InputRow>
										<S.InputGroup size={6 / 12}>
											<S.Input
												placeholder='Name'
												{...formix.$("name").bind()}
												disabled={localStore.pending}
												required
												minLength={3}
												maxLength={255}
											/>
										</S.InputGroup>
										<S.InputGroup size={6 / 12}>
											<S.Input
												placeholder='Phone'
												{...formix.$("phone").bind()}
												disabled={localStore.pending}
												required
												pattern='^[^a-zA-Z]+$'
											/>
										</S.InputGroup>
									</S.InputRow>
									<S.InputRow>
										<S.InputGroup size={6 / 12}>
											<S.Input
												type='email'
												placeholder='Email'
												{...formix.$("email").bind()}
												disabled={localStore.pending}
												required
											/>
										</S.InputGroup>
										<S.InputGroup size={6 / 12}>
											<S.Input
												placeholder='Brand'
												{...formix.$("brand").bind()}
												disabled={localStore.pending}
												required
												minLength={2}
												maxLength={255}
											/>
										</S.InputGroup>
									</S.InputRow>
									<S.InputRow>
										<S.InputGroup size={1}>
											<S.Textarea
												as='textarea'
												placeholder='Details'
												{...formix.$("details").bind()}
												disabled={localStore.pending}
											/>
										</S.InputGroup>
									</S.InputRow>
								</S.FormContent>
								<S.GetReadyButtonGroup>
									<CursorFollower areaOffset={20} enabled={!localStore.pending}>
										<S.GetReadyButton {...cursor.bind()} type='submit' disabled={localStore.pending}>
											Get in touch
										</S.GetReadyButton>
									</CursorFollower>
								</S.GetReadyButtonGroup>
							</form>
						</S.Container>
					</S.Content>
				</S.ContentGroup>
			</S.FeedbackOverlay>
		</>
	);
});
