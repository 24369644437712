import { createContext, useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { SpringValue, useSpring } from "react-spring";
import { reaction } from "mobx";
import useRefs from "react-use-refs";

import { useLocalStore, useResizeObserver } from "@core/hooks";
import { clamp } from "@core/utils";

import * as S from "./styled";

export interface Context {
	store: { pages: number; containerHeight: number; pseudoScrollEnabled: boolean };
	animated: { offset: SpringValue<number>; pseudoScrollEnabled: SpringValue<number> };
}

export interface Props {
	enabled?: boolean;
	children?: React.ReactNode;
	minHeight?: string | number;
	pseudoScrollEnabled?: boolean;
}

export const ScrollControls: React.FC<Props> = ({
	children,
	minHeight,
	enabled = true,
	pseudoScrollEnabled = false,
}) => {
	const [containerRef, fixedRef, fillRef, contentRef] = useRefs<HTMLDivElement>(null);
	const containerResizeObserver = useResizeObserver({ ref: containerRef });
	const contentResizeObserver = useResizeObserver({ ref: contentRef });
	const localStore = useLocalStore<Context["store"]>({
		pages: 0,
		containerHeight: 0,
		pseudoScrollEnabled,
	});
	const [contentStyle, contentAnimationControl] = useSpring(() => ({
		offset: 0,
		pseudoScrollEnabled: Number(pseudoScrollEnabled),
		onChange: {
			offset: async (value: number) => {
				if (contentStyle.pseudoScrollEnabled.get() && value >= 1 / localStore.pages) {
					const container = containerRef.current!;
					const fill = fillRef.current!;

					const newValue = value - 1 / localStore.pages;

					await Promise.all([
						Promise.resolve(contentAnimationControl.set({ pseudoScrollEnabled: 0 })),
						Promise.resolve(contentAnimationControl.set({ offset: newValue })),
						Promise.resolve(
							(() => {
								container.scrollTop = newValue * (localStore.pages - 1) * window.innerHeight;
								fill.style.minHeight = "";
							})()
						),
					]);

					localStore.setPseudoScrollEnabled(false);
				}
			},
		},
	}));
	const animatedStore = useMemo<Context["animated"]>(
		() => ({ offset: contentStyle.offset, pseudoScrollEnabled: contentStyle.pseudoScrollEnabled }),
		[contentStyle]
	);

	const calculateOffset = useCallback(
		(scrollTop: number, scrollHeight: number) => {
			const containerHeight = containerResizeObserver.getSize().height;
			const offset = scrollTop / Math.max(0.0001, scrollHeight - containerHeight);
			return clamp(offset, 0, 1);
		},
		[containerResizeObserver]
	);

	const calculatePages = useCallback(() => {
		const containerHeight = containerResizeObserver.getSize().height;
		const contentHeight = contentResizeObserver.getSize().height;
		return contentHeight / containerHeight;
	}, [containerResizeObserver, contentResizeObserver]);

	const handleScroll = useCallback(() => {
		if (!enabled) {
			return;
		}

		const container = containerRef.current!;
		const { scrollTop, scrollHeight } = container;
		contentAnimationControl.start({ offset: calculateOffset(scrollTop, scrollHeight) });
	}, [calculateOffset, containerRef, contentAnimationControl, enabled]);

	useEffect(
		() =>
			reaction(
				() => contentResizeObserver.getSize().height,
				(height) => {
					const fill = fillRef.current;
					fill!.style.height = `${height}px`;
					// spring.set(scrollTop / size.height);
				}
			),
		[contentResizeObserver, fillRef]
	);

	useEffect(
		() =>
			reaction(
				() => [containerResizeObserver.getSize().height, contentResizeObserver.getSize().height],
				([containerHeight]) => {
					const container = containerRef.current!;
					const { scrollTop, scrollHeight } = container;
					const pages = calculatePages();

					localStore.setPages(pages);
					localStore.setContainerHeight(containerHeight);
					contentAnimationControl.set({ offset: calculateOffset(scrollTop, scrollHeight) });
				}
			),
		[
			calculateOffset,
			calculatePages,
			containerRef,
			containerResizeObserver,
			contentAnimationControl,
			contentResizeObserver,
			localStore,
		]
	);

	useLayoutEffect(() => {
		const container = containerRef.current!;
		container.scrollTop = 1;
		container.addEventListener("scroll", handleScroll, { passive: true });

		return () => {
			container.removeEventListener("scroll", handleScroll);
		};
	}, [containerRef, handleScroll]);

	return (
		<S.ScrollControls>
			<S.Container ref={containerRef} style={{ overflow: enabled ? "hidden auto" : "hidden" }}>
				<S.Fixed ref={fixedRef}>
					<context.Provider value={{ store: localStore, animated: animatedStore }}>
						<S.Content
							ref={contentRef}
							style={{
								y: contentStyle.offset.to((value) => {
									const containerHeight = containerResizeObserver.getSize().height;
									const y = -(value * (localStore.pages - 1)) * containerHeight;
									return y;
								}),
							}}>
							{children}
						</S.Content>
					</context.Provider>
				</S.Fixed>
				<S.Fill ref={fillRef} style={{ minHeight }} />
			</S.Container>
		</S.ScrollControls>
	);
};

export const context = createContext<Context>(null!);
